// Step 1: Import React
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Layout} from "../../../components/Layout"
import Button from '../../../components/buttons/button'
import ProductBanner from "../../../components/product_banners/product_banners";
import SlickProduct from "../../../components/product_slick/slick_product";
import Faq from "../../../components/faq/faq";
import Banner from "../../../imgs/banner-landing-cedears-de-etf.jpg";
import IframeVIdeo from "../../../components/iframe_video/iframe_video";
import "./cedear-de-etf.css";
import faqjson from "../../../misc/cedears_related/faq.json";
import slick_items from "../../../misc/cedears_efts_related/slick_items_cedears.json";
import { SalWrapper } from "react-sal";
import { apiRequest } from "../../../apiRquest/apiRequest";
import { Helmet } from "react-helmet";
import { graphql, Link, useStaticQuery } from "gatsby";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
const pageTitle = 'Invertí en CEDEARs de ETFs desde Argentina | Balanz';
const pageDesc = 'Invertí en los principales ETFs del mundo a través de CEDEARs desde tu cuenta de inversión local. Operalos en pesos o en dólares y ampliá tu cartera.';
let text = `Creemos que existen tantas inversiones como inversores, <span class='highlightsbold'> te presentamos CEDEARs de ETFs.</span>`;

const { SearchBar } = Search;
const columns = [
    {
        dataField: "etf",
        text: "ETF",
        sort: true,
        classes: 'bg-lt-grey text-start etf_col_cell',
        headerClasses: "etf_col",
    },
    {
        dataField: "ticker",
        text: "Ticker",
        sort: true,
        headerClasses: "ticker_col",
    },
    {
        dataField: "market",
        text: "Mercado donde cotiza",
        sort: true,
        headerClasses: "market_col",
    },
    {
        dataField: "ratio",
        text: "Ratio de conversión",
        sort: true,
        headerClasses: "ratio_col"
    },
    {
        dataField: "direct_access",
        text: "Acceso directo",
        headerClasses: "direct_access_col",
        formatter: (direct_access, row, rowIndex) => {
            //if(row.idFondo !==34 && row.idFondo !== 38){
            if(direct_access) {
                return (
                    <div className="btnholder d-grid" key={rowIndex}>
                        {/*<Link to={`/inversiones/fondos/${link}`}>*/}
                        <a href={direct_access} target="_blank">
                            <Button
                                text="Invertir"
                                variant="secondary data_table_button"/>
                        </a>
                    </div>
                );
            }
           // }
        }
    },
];

const defaultSorted = [
    {
        dataField: "name",
        order: "desc",
    },
];

const CedearsPage = () => {
    const [xs, setXs] = useState(false);
    const [dataTable] = useState([
        {
            "etf": "INVESCO QQQ TRUST",
            "ticker": "QQQ",
            "market": "NASDAQ",
            "ratio": "20:1",
            "direct_access": "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJ0aWNrZXIiOiJRUVEiLCJwYW5lbCI6IjciLCJwbGF6byI6IjIiLCJpZFBsYXpvIjoiMiJ9fQ"
        },
        {
            "etf": "ARK INNOVATION",
            "ticker": "ARKK",
            "market": "NYSE Arca",
            "ratio": "10:1",
            "direct_access": "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJ0aWNrZXIiOiJBUktLIiwicGFuZWwiOiI3IiwicGxhem8iOiIyIiwiaWRQbGF6byI6IjIifX0"
        },
        {
            "etf": "SPDR S&P 500",
            "ticker": "SPY",
            "market": "NYSE Arca",
            "ratio": "20:1",
            "direct_access": "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJ0aWNrZXIiOiJTUFkiLCJwYW5lbCI6IjciLCJwbGF6byI6IjIiLCJpZFBsYXpvIjoiMiJ9fQ"
        },
        {
            "etf": "ISHARES TRUST RUSSELL 2000",
            "ticker": "IWM",
            "market": "NYSE Arca",
            "ratio": "10:1",
            "direct_access": "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJ0aWNrZXIiOiJJV00iLCJwYW5lbCI6IjciLCJwbGF6byI6IjIiLCJpZFBsYXpvIjoiMiJ9fQ"
        },
        {
            "etf": "ISHARES MSCI EMERGING MARKET",
            "ticker": "EEM",
            "market": "NYSE Arca",
            "ratio": "5:1",
            "direct_access": "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJ0aWNrZXIiOiJFRU0iLCJwYW5lbCI6IjciLCJwbGF6byI6IjIiLCJpZFBsYXpvIjoiMiJ9fQ"
        },
        {
            "etf": "FINANCIAL SELECT SECTOR SPDR FUND",
            "ticker": "XLF",
            "market": "NYSE Arca",
            "ratio": "2:1",
            "direct_access": "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJ0aWNrZXIiOiJYTEYiLCJwYW5lbCI6IjciLCJwbGF6byI6IjIiLCJpZFBsYXpvIjoiMiJ9fQ"
        },
        {
            "etf": "ENERGY SELECT SECTOR SPDR FUND",
            "ticker": "XLE",
            "market": "NYSE Arca",
            "ratio": "2:1",
            "direct_access": "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJ0aWNrZXIiOiJYTEUiLCJwYW5lbCI6IjciLCJwbGF6byI6IjIiLCJpZFBsYXpvIjoiMiJ9fQ"
        },
        {
            "etf": "SPDR DOW JONES INDUSTRIAL",
            "ticker": "DIA",
            "market": "NYSE Arca",
            "ratio": "20:1",
            "direct_access": "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJ0aWNrZXIiOiJESUEiLCJwYW5lbCI6IjciLCJwbGF6byI6IjIiLCJpZFBsYXpvIjoiMiJ9fQ"
        },
        {
            "etf": "ISHARES MSCI BRAZIL CAP ",
            "ticker": "EWZ",
            "market": "NYSE Arca",
            "ratio": "2:1",
            "direct_access": "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJ0aWNrZXIiOiJFV1oiLCJwYW5lbCI6IjciLCJwbGF6byI6IjIiLCJpZFBsYXpvIjoiMiJ9fQ"
        },
        {
            "etf": "ISHARE BITCOIN TRUST",
            "ticker": "IBIT",
            "market": "NASDAQ",
            "ratio": "10:1",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6ew0KCSJyZXF1aWVyZWxvZ2luIjoiMSIsDQoJIm5hbWVzcGFjZSI6IkNvcmUuQnVzaW5lc3MuRGV0YWxsZUluc3RydW1lbnRvIiwNCgkidGlja2VyIjoiSUJJVCIsDQoJInBhbmVsIjoiNyIsDQoJInBsYXpvIjoiMSIsDQoJImlkUGxhem8iOiIxIiwNCgkiaWRDYW1wYWlnbiI6IklCSVQtQ0VERUFSLVdFQi0yMDI0MTIxMi1ERUVQTElOSyIsDQoJImNhbXBhaWduX2lkIjoiSUJJVC1DRURFQVItV0VCLTIwMjQxMjEyLURFRVBMSU5LIg0KICB9fQ"
        },
        {
            "etf": "ISHARE ETHEREUM TRUST",
            "ticker": "ETHA",
            "market": "NASDAQ",
            "ratio": "5:1",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6ew0KCSJyZXF1aWVyZWxvZ2luIjoiMSIsDQoJIm5hbWVzcGFjZSI6IkNvcmUuQnVzaW5lc3MuRGV0YWxsZUluc3RydW1lbnRvIiwNCgkidGlja2VyIjoiRVRIQSIsDQoJInBhbmVsIjoiNyIsDQoJInBsYXpvIjoiMSIsDQoJImlkUGxhem8iOiIxIiwNCgkiaWRDYW1wYWlnbiI6IkVUSEEtQ0VERUFSLVdFQi0yMDI0MTIxMi1ERUVQTElOSyIsDQoJImNhbXBhaWduX2lkIjoiRVRIQS1DRURFQVItV0VCLTIwMjQxMjEyLURFRVBMSU5LIg0KICB9fQ"
        },
        {
            "etf": "ISHARES TRUST FTSE CHINA 25 IF",
            "ticker": "FXI",
            "market": "NYSE Arca",
            "ratio": "5:1",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6ew0KCSJyZXF1aWVyZWxvZ2luIjoiMSIsDQoJIm5hbWVzcGFjZSI6IkNvcmUuQnVzaW5lc3MuRGV0YWxsZUluc3RydW1lbnRvIiwNCgkidGlja2VyIjoiRlhJIiwNCgkicGFuZWwiOiI3IiwNCgkicGxhem8iOiIxIiwNCgkiaWRQbGF6byI6IjEiLA0KCSJpZENhbXBhaWduIjoiRlhJLUNFREVBUi1XRUItMjAyNDEyMTItREVFUExJTksiLA0KCSJjYW1wYWlnbl9pZCI6IkZYSS1DRURFQVItV0VCLTIwMjQxMjEyLURFRVBMSU5LIg0KICB9fQ"
        },
        {
            "etf": "PROSHARE SHORT S&P500",
            "ticker": "SH",
            "market": "NYSE",
            "ratio": "8:1",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6ew0KCSJyZXF1aWVyZWxvZ2luIjoiMSIsDQoJIm5hbWVzcGFjZSI6IkNvcmUuQnVzaW5lc3MuRGV0YWxsZUluc3RydW1lbnRvIiwNCgkidGlja2VyIjoiU0giLA0KCSJwYW5lbCI6IjciLA0KCSJwbGF6byI6IjEiLA0KCSJpZFBsYXpvIjoiMSIsDQoJImlkQ2FtcGFpZ24iOiJTSC1DRURFQVItV0VCLTIwMjQxMjEyLURFRVBMSU5LIiwNCgkiY2FtcGFpZ25faWQiOiJTSC1DRURFQVItV0VCLTIwMjQxMjEyLURFRVBMSU5LIg0KICB9fQ"
        },
        {
            "etf": "SPDR GOLD TRUST",
            "ticker": "GLD",
            "market": "NYSE",
            "ratio": "50:1",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6ew0KCSJyZXF1aWVyZWxvZ2luIjoiMSIsDQoJIm5hbWVzcGFjZSI6IkNvcmUuQnVzaW5lc3MuRGV0YWxsZUluc3RydW1lbnRvIiwNCgkidGlja2VyIjoiR0xEIiwNCgkicGFuZWwiOiI3IiwNCgkicGxhem8iOiIxIiwNCgkiaWRQbGF6byI6IjEiLA0KCSJpZENhbXBhaWduIjoiR0xELUNFREVBUi1XRUItMjAyNDEyMTItREVFUExJTksiLA0KCSJjYW1wYWlnbl9pZCI6IkdMRC1DRURFQVItV0VCLTIwMjQxMjEyLURFRVBMSU5LIg0KICB9fQ"
        },

    ]);

    const FaqsData = useStaticQuery(graphql`
        {
            allFaqs(
                filter: {category_id: {in: 15}},
                sort: {order: ASC, fields: [category_id, order]}
            ) {
                nodes {
                    category_id
                    id
                    answer
                    question
                }
            }
        }
    `);

    const [faq, setFaq] = useState({
        list: FaqsData.allFaqs.nodes
    });

    useEffect(() => {
        const handleXs = () => {
            const isXs = window.innerWidth <= 768;
            setXs(isXs);
        };

        window.addEventListener('resize', handleXs);

        handleXs();

        return () => {
            window.removeEventListener("resize", handleXs);
        };
    }, []);

  const slickSettings = {
      centerMode: false,
      slidesToShow: 5,
      dots: true,
      arrows: false,
      responsive: [
          {
              breakpoint: 2920,
              settings:"unslick"
          },
          /*{
              breakpoint: 1200,
              settings: { slidesToShow: 5, slidesToScroll: 5, infinite: false }
          },*/
          {
              breakpoint: 992,
              settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false }
          },
          {
              breakpoint: 768,
              settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false }
          },
          {
              breakpoint: 576,
              settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
          }
      ]
  };
    useEffect(() => {
        apiRequest
            .get("/v1/faq/15")
            .then((res) => {
                const data = []
                res.data.data.forEach(d => {
                    data.push({
                        question: d.question,
                        answer: d.answer
                    })

                })

                setFaq((old) => ({...old, list: data}));
            })
            .catch((err) => {
                console.log(err);
            })
    }, [setFaq])
  const page = (
    <>
        <Helmet>
            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "¿Qué son los CEDEARs?",
                        "description": "Un CEDEAR es un Certificado de Depósito Argentino que representa un activo del exterior que cotiza en Argentina a través de un certificado.",
                        "thumbnailUrl": "https://www.youtube.com/watch?v=IidfGtULiKs",
                        "uploadDate": "2022-07-08",
                        "duration": "PT0M55S",
                        "contentUrl": "https://balanz.com/inversiones/cedears/",
                        "embedUrl": "https://balanz.com/inversiones/cedears/"
                    }
                `}
            </script>

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "¿Cómo invertir en CEDEARs?",
                        "description": "Es muy fácil, lo hacés directamente desde nuestra plataforma.",
                        "thumbnailUrl": "https://www.youtube.com/watch?v=w3Ldre7Metc",
                        "uploadDate": "2022-07-08",
                        "duration": "PT0M55S",
                        "contentUrl": "https://balanz.com/inversiones/cedears/",
                        "embedUrl": "https://balanz.com/inversiones/cedears/"
                    }
                `}
            </script>
        </Helmet>

        <ProductBanner
          bannerContainerClassName="bannerCedear"
          bannerTrasparecy="bannerMask"
          productTitle="CEDEARs de ETFs"
          bannerImage={Banner}
          bannerImageClassName="cedearsBannerImage"
          textContainerClassName="textContainer"
          bannerText={text}
          bannerTextClassName="bannerText1"
          bannerImageMobile={Banner}
          mobile={false}
        />
        <SalWrapper>
        <section className="fullwidth product-description">
            <Container>
                <Row className="">
                    <h2
                        data-sal="fade"
                        data-sal-delay="500"
                        data-sal-duration="400"
                    >¿Qué son los ETFs?</h2>
                </Row>
            </Container>
            <div className="bg-lt-grey"
                 data-sal="fade"
                 data-sal-delay="100"
                 data-sal-duration="200"
            >
                <Container>
                    <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                        <Col xs={12} >
                            <p className="product-text pt-2 pt-sm-3 pt-md-4 pt-lg-5">
                                Los <strong>Exchange Traded Funds</strong> (ETFs) son conjuntos de activos que pueden estar compuestos por acciones, bonos y commodities, entre otros. Son similares a los Fondos Comunes de Inversión ya que el inversor accede, con un solo vehículo de inversión, a un conjunto de activos. Sin embargo, los ETFs cotizan en bolsa y se pueden comprar o vender en tiempo real en el mercado como si fueran acciones.
                                <br />
                                El objetivo de inversión de los más conocidos, es replicar valores de índices bursátiles de diversos sectores de la economía. En Argentina, los ETFs cotizan bajo el formato de CEDEAR, en pesos y en dólares.
                            </p>
                            <div className="pt-4 text-center"
                                 data-sal="fade"
                                 data-sal-delay="300"
                                 data-sal-duration="300"
                            >
                                <Button id="cedears_invertir_1" variant="secondary" text="Invertir" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container
                data-sal="fade"
                data-sal-delay="100"
                data-sal-duration="200"
            >
                <Row className="py-5 justify-content-center">
                    <Col xs={12} md={10} lg={8} className="d-lg-flex justify-content-lg-center">
                        <IframeVIdeo
                            src="https://www.youtube.com/embed/xnWmupb8g5g"
                            resolutionClass={null}
                        />
                    </Col>
                </Row>
                <Row className="gx-5 gy-4 pt-3 pt-sm-2 pt-md-3 pt-md-4 pt-lg-5">
                    <Col xs={12}>
                        <p className="product-text">
                            Suelen dividirse en dos grupos, de acuerdo a su estrategia de inversión. Están los de administración activa y los de administración pasiva.
                        </p>

                        <p className="product-text">
                            <strong>ETF de administración activa:</strong> cuentan con un portfolio manager que toma decisiones de inversión de acuerdo al posicionamiento del fondo. La cartera puede variar, ofrece la posibilidad de obtener rentabilidades positivas aun en años negativos para la bolsa o, incluso rentabilidades superiores al índice.
                        </p>
                        <p className="product-text">
                            <strong>ETF de administración pasiva:</strong> buscan correlacionar los retornos del ETF con los del índice de referencia. Este tipo de fondos pueden ser de réplica física o de réplica sintética.
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="fullwidth rentabilidadestable">
             <Container>
                <Row className="gx-5 py-3 py-md-4 py-lg-5">
                  <Col xs={12} md={12} className='colums-table'>
                    <h2 className="mt-2">¿Cuáles son los 13 CEDEARs de ETFs que podés operar desde tu cuenta Balanz?</h2>
                    {dataTable.length > 0 && (

                        <ToolkitProvider
                            bootstrap4
                            keyField="key"
                            data={dataTable}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            search
                        >
                            {
                                props => (
                                    <div className="toolkit-holders">
                                        <BootstrapTable { ...props.baseProps }
                                            classes="custom_table comparative-table table-responsive table-responsive-min"
                                            headerWrapperClasses="data_table_header"
                                            wrapperClasses="table-responsive"
                                        />
                                        {/*<BootstrapTable { ...props.baseProps }
                                         classes="custom_table comparative-table table-responsive"
                                         headerWrapperClasses="data_table_header"
                                         bootstrap4
                                         keyField="key"
                                         data={dataTable}
                                         columns={columns}
                                         defaultSorted={defaultSorted}
                                         wrapperClasses="table-responsive"
                                        />*/}
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    )}

                    {/* <div class="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <th>ETF</th>
                                <th>Ticker</th>
                                <th>Mercado donde cotiza</th>
                                <th>Ratio de conversión</th>
                                <th>Acceso directo</th>
                            </thead>
                            <tbody>

                                <tr>
                                    <td>ARK INNOVATION</td>
                                    <td>ARKK</td>
                                    <td>NYSE Arca</td>
                                    <td>10:1</td>
                                    <td>
                                        <Button size="sm" variant="secondary" text="Invertir" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>SPDR S&P 500</td>
                                    <td>SPY</td>
                                    <td>NASDAQ</td>
                                    <td>20:1</td>
                                    <td>
                                        <Button size="sm" variant="secondary" text="Invertir" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>ISHARES TRUST RUSSELL 2000</td>
                                    <td>IWM</td>
                                    <td>NYSE Arca</td>
                                    <td>10:1</td>
                                    <td>
                                        <Button size="sm" variant="secondary" text="Invertir" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>ISHARES MSCI EMERGING MARKET</td>
                                    <td>EEM</td>
                                    <td>NYSE Arca</td>
                                    <td>5:1</td>
                                    <td>
                                        <Button size="sm" variant="secondary" text="Invertir" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>FINANCIAL SELECT SECTOR SPDR FUND</td>
                                    <td>XLF</td>
                                    <td>NYSE Arca</td>
                                    <td>2:1</td>
                                    <td>
                                        <Button size="sm" variant="secondary" text="Invertir" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>ENERGY SELECT SECTOR SPDR FUND</td>
                                    <td>XLE</td>
                                    <td>NYSE Arca</td>
                                    <td>2:1</td>
                                    <td>
                                        <Button size="sm" variant="secondary" text="Invertir" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>SPDR DOW JONES INDUSTRIAL</td>
                                    <td>DIA</td>
                                    <td>NYSE Arca</td>
                                    <td>20:1</td>
                                    <td>
                                        <Button size="sm" variant="secondary" text="Invertir" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>ISHARES MSCI BRAZIL CAP</td>
                                    <td>EWZ</td>
                                    <td>NYSE Arca</td>
                                    <td>2:1</td>
                                    <td>
                                        <Button size="sm" variant="secondary" text="Invertir" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}
                  </Col>
                </Row>
             </Container>
        </section>
        <section className="fullwidth razones">
             <Container>
                 <Row>
                    <h2 className="mt-2"
                        data-sal="fade"
                        data-sal-delay="0"
                        data-sal-duration="400"
                    >5 Razones para invertir en CEDEARs de ETFs</h2>

                  <SlickProduct
                    slickSettings={slickSettings}
                    list={slick_items}
                  />
                </Row>
             </Container>
         </section>
        <section className="fullwidth como-operar"
                 data-sal="fade"
                 data-sal-delay="0"
                 data-sal-duration="400"
        >
             <Container>
                <Row className="gx-5 py-3 py-md-4 py-lg-5">
                  <Col xs={12} md={6}>
                    <h2 className="mt-2">¿Cómo invertir en CEDEARS de ETFs?</h2>
                      <p className="product-text me-0 me-sm-1 me-md-3 me-lg-4 me-xl-5 mb-2 mb-lg-5">
                          Es muy fácil, lo hacés directamente desde nuestra plataforma. <br/>Te recomendamos conocer tu perfil de inversor y analizar junto con tu asesor, si es necesario, cuál es la mejor opción para vos.</p>
                      <div className="product-cta text-start text-md-start pb-5 pb-md-0 pt-2 pt-sm-3 pt-md-4 pt-lg-5">
                          <Button id="cedears_invertir_2" variant={`secondary ${xs ? 'chevron-right': ''}`}  text="Invertir" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                      </div>

                  </Col>
                  <Col xs={12} md={6} className="d-lg-flex justify-content-lg-end align-items-center">
                    <IframeVIdeo
                        src="https://www.youtube.com/embed/TXYcBXvAnpY"
                      resolutionClass={null}
                    />
                  </Col>
                </Row>
                 {/*<Row className="d-block d-md-none">
                     <div className="text-center pt-4 p-lg-3">
                         <Button variant="secondary" text="Invertir" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                     </div>
                 </Row>*/}
             </Container>
        </section>

        <section className="fullwidthbg pack-de-cedears-banner-2 py-4 py-md-5">
            <Container className="d-flex align-items-center justify-content-center">
                   <Row className="d-flex align-items-strech py-3 py-sm-4 py-md-4 gx-5 w-100 ">
                    <Col xs={12} md={12}>
                        <div className="section-content banner2-Title text-center"
                             data-sal="slide-up"
                             data-sal-delay="0"
                             data-sal-duration="200"
                        >
                            <h3 className="titulo me-3 me-md-3 me-lg-4 me-xl-5 mb-4" ><span className="tit-54"><strong>¿Querés empezar a invertir en CEDEARs de ETFs? </strong></span></h3>
                            
                            <span className=" subtitle  me-3 me-md-3 me-lg-4 me-xl-5" ><span className="tit-54">Abrí hoy tu cuenta de inversión en Balanz, sin costo de apertura ni mantenimiento, y accedé al mejor asesoramiento del mercado.</span></span>
                            
                        </div>
                        
                    </Col>
                    <div className="cta mt-5 mb-2 text-center pt-4 p-lg-3"
                             data-sal="slide-up"
                             data-sal-delay="300"
                             data-sal-duration="200"
                        >   
                            <Link to="https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1382">
                                <Button variant="secondary" text="Abrir Cuenta"/>
                            </Link>

                            
                        </div> 

                  </Row>
            </Container>
        </section>

        <section className="fullwidth preguntas-frecuentes">
            <Container>
                <Row>
                  <Faq faq={faq} customClass={'cedears-faq'} />
                </Row>
              </Container>
        </section>
        </SalWrapper>
    </>
  )

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"cedears"}></Layout>
        </main>
    );
};

// Step 3: Export your component
export default CedearsPage;
